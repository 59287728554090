<template>
    <div class="card lg:px-9">
        <div class="card">
            <TabMenu v-model:activeIndex="active" :model="items" />

            <DataTable v-if="active === 0" v-model:filters="filters" :value="getBrowser" stripedRows paginator :rows="50" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 70rem">
                <template #header>
                    <div class="flex justify-content-end">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Search..." />
                        </span>
                    </div>
                </template>
                <template #empty> Not found. </template>

                <Column field="created_at" header="Date">
                    <template #body="{ data }">
                        <span>{{ convertDate(data.created_at) }}</span>
                    </template>
                </Column>
                <Column field="user_uuid" header="User">
                    <template #body="{ data }">
                        <span>{{ data.firstname.toUpperCase() }} {{ data.lastname.toUpperCase() }}</span>
                    </template>
                </Column>
                <Column field="search" header="Search"></Column>
                <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <Button @click="redirectToUser(data)" type="button" icon="pi pi-user" rounded />
                    </template>
                </Column>
            </DataTable>
            <DataTable v-if="active === 1" v-model:filters="filters" :value="getProducts" stripedRows paginator :rows="10" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 70rem">
                <template #header>
                    <div class="flex justify-content-end">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Search..." />
                        </span>
                    </div>
                </template>
                <template #empty> Not found. </template>

                <Column field="created_at" header="Date">
                    <template #body="{ data }">
                        <span>{{ convertDate(data.created_at) }}</span>
                    </template>
                </Column>
                <Column field="user_uuid" header="User">
                    <template #body="{ data }">
                        <span>{{ data.firstname.toUpperCase() }} {{ data.lastname.toUpperCase() }}</span>
                    </template>
                </Column>
                <Column field="isbn" header="ISBN"></Column>
                <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <Button v-if="data.user_uuid" @click="redirectToUser(data)" type="button" icon="pi pi-user" rounded />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import m from 'moment';
import { FilterMatchMode } from 'primevue/api';

export default {
    name: 'browserHistory',
    data() {
        return {
            active: 0,
            items: [
                { label: 'Browser', icon: 'pi pi-search' },
                { label: 'Product', icon: 'pi pi-filter' }
            ],
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
        };
    },
    computed: {
        ...mapGetters({
            getBrowser: 'getBrowser',
            getProducts: 'getProducts'
        }),
    },
    methods: {
        ...mapActions({
            historyBrowser: 'historyBrowser',
            historyProducts: 'historyProducts',
            user: 'user',
        }),
        convertDate(data) {
            return m(data).format('DD/MM/YYYY HH:mm:ss');
        },
        async redirectToUser(user) {
            await this.user(user.user_uuid).then(() => {
                this.$router.push('/dashboard/user/' + user.user_uuid);
            })
        },
    },
    mounted() {
        this.historyBrowser();
        this.historyProducts();
    }
};
</script>