<template>
    <div class="card lg:px-9">
        <Button @click="generateScrapingStatistics()" icon="pi pi-cloud-download" type="button" label="Generate scraping statistics" severity="success" />
    </div>
</template>

<script>  
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'statisticsXLSX',
    components: {
    },
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
        }),
    },
    methods: {
        ...mapActions({
            scrapingStatisticsXLSX: 'scrapingStatisticsXLSX'
        }),
        async generateScrapingStatistics() {
            await this.scrapingStatisticsXLSX();
        }
    },
};
</script>