import { api } from '@/services/interceptors';
import router from '../router/index';

export default {
  state: {
    auth: false,
    pings: [],
    prod_stats: []
  },
  getters: {
    getIsAuth(state) {
      return state.auth;
    },
    getPings(state) {
      return state.pings;
    },
    getProdStats(state) {
      return state.prod_stats;
    },
  },
  actions: {
    login({ commit }, payload) {
      api.post('api/login', payload)
        .then((res) => {
          if (res.data.token) {
            commit('STORAGE_TOKEN', res.data.token);
          }
          router.push('/2fa');
        })
      },
      doubleFA({ commit }, payload) {
        return new Promise((resolve, reject) => {
          api.post('api/2fa', payload)
            .then((res) => {
              if (res.data.token) {
                commit('STORAGE_TOKEN', res.data.token);
              }
              
              commit('SET_AUTH', true);
              router.push('/dashboard');
              resolve();
            })
            .catch(() => {
              reject();
            })
        })
      },
      logout({ commit }) {
        api.get('api/logout')
          .then(() => {
            router.push('/');
            localStorage.removeItem('token');
            commit('SET_AUTH', false);
          })
      },
      ping({ commit }) {
        return new Promise((resolve, reject) => {
          api.get('api/ping')
            .then((response) => {
              commit('SET_PING_INFOS', response.data.results);
              commit('SET_PROD_STATS', response.data.prod_stats);
              commit('SET_AUTH', true);
              resolve();
            }).catch(() => {
              reject();
            })
        })
      },
  },
  mutations: {
    SET_AUTH(currentState, state) {
      currentState.auth = state;
    },
    STORAGE_TOKEN(currentState, token) {
        console.log(currentState);

        if (process.env.NODE_ENV !== 'production') {
            console.warn('Set token in LS - dev/preprod mode');
            localStorage.setItem('token', token);
        }
    },
    SET_PING_INFOS(currentState, state) {
      currentState.pings = state;
    },
    SET_PROD_STATS(currentState, stats) {
      currentState.prod_stats = stats;
    },
    STORE_NOTHING() { }
  }
};