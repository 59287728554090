<template>
    <div class="card lg:px-9 mt-3">
        <Chart type="line" :data="chartData" :options="chartOptions" />
    </div>
</template>

<script>  
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'statisticsChart',
    components: {
    },
    data() {
        return {
            chartData: null,
            chartOptions: null
        };
    },
    computed: {
        ...mapGetters({
            getUsersStatistics: 'getUsersStatistics'
        }),
    },
    methods: {
        ...mapActions({
            userStatistics: 'userStatistics'
        }),
        setChartData() {
            return {
                labels: this.getUsersStatistics.visitorsChart30Days.labels,
                datasets: [
                    {
                        label: 'Unique visitors',
                        data: this.getUsersStatistics.visitorsChart30Days.datas,
                        backgroundColor: 'rgba(6, 182, 212)',
                        borderColor: 'rgb(6, 182, 212)',
                        borderWidth: 1
                    },
                    {
                        label: 'Registration',
                        data: this.getUsersStatistics.usersAccounts.datas,
                        backgroundColor: 'rgb(139, 92, 246)',
                        borderColor: 'rgb(139, 92, 246)',
                        borderWidth: 1
                    },
                    {
                        label: 'Products check',
                        data: this.getUsersStatistics.enterInProduct.datas,
                        backgroundColor: 'rgb(251, 192, 45)',
                        borderColor: 'rgb(251, 192, 45)',
                        borderWidth: 1
                    },
                    {
                        label: 'Connection',
                        data: this.getUsersStatistics.connectionHistory.datas,
                        backgroundColor: 'rgb(58, 213, 49)',
                        borderColor: 'rgb(58, 213, 49)',
                        borderWidth: 1
                    }
                ]
            };
        },
        setChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');
            const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
            const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

            return {
                plugins: {
                    legend: {
                        labels: {
                            color: textColor
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    },
                    y: {
                        beginAtZero: true,
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    }
                }
            };
        }
    },
    mounted() {
        this.chartData = this.setChartData();
        this.chartOptions = this.setChartOptions();
    },
};
</script>

<style>
    .m-center {
        margin: auto;
    }
</style>