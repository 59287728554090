<template>
    <div class="card lg:px-9">
        <TabMenu v-model:activeIndex="activeStatisticsTab" :model="items" />

        <div class="card flex align-items-center mt-2 mb-2">
            <!-- <Calendar v-model="transactionsReport.start" dateFormat="yy-mm-dd" placeholder="Start" showIcon class="ml-2" />
            <Calendar v-model="transactionsReport.end" dateFormat="yy-mm-dd" placeholder="End" showIcon class="ml-2" /> -->
            <Button @click="dowloadTransactionsXLSX()" class="ml-2" icon="pi pi-download" type="button" label="Download transactions" severity="success" />
        </div>

        <div v-if="activeStatisticsTab === 0" class="mt-2">
            <DataTable v-model:filters="filters" :value="filterTransactions" stripedRows paginator :rows="100" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 50rem">
                <template #header>
                    <div class="flex justify-content-end">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </span>
                    </div>
                </template>
                <template #empty> No users found. </template>

                <Column field="created_at" header="Date" bodyStyle="font-size: 14px;" style="max-width: 1rem">
                    <template #body="{ data }">
                        <span>{{ convertDate(data) }}</span>
                    </template>
                </Column>

                <Column field="firstname" header="Buyer" bodyStyle="font-size: 14px;" style="max-width: 1rem">
                    <template #body="{ data }">
                        <a @click="redirectToUser(data)" href="#" class="mr-2">
                            <span>{{ returnIdentity(data) }}</span>
                        </a>
                    </template>
                </Column>

                <Column field="firstname_seller" header="Seller" bodyStyle="font-size: 14px;" style="max-width: 1rem">
                    <template #body="{ data }">
                        <a @click="redirectToUserSeller(data)" href="#" class="mr-2">
                            <span>{{ returnSellerIdentity(data.invoice_id) }}</span>
                        </a>
                    </template>
                </Column>

                <Column field="order_type" header="Type" bodyStyle="font-size: 14px;" style="max-width: 1rem">
                    <template #body="{ data }">
                        <span>{{ returnOrderType(data.order_type) }}</span>
                    </template>
                </Column>

                <Column field="title" header="Title" bodyStyle="font-size: 14px;" style="max-width: 4rem">
                    <template #body="{ data }">
                        <span>{{ data.infos[0]?.name }}</span>
                    </template>
                </Column>

                <Column field="ups_package_status" header="Track" bodyStyle="font-size: 14px;" style="max-width: 1rem">
                    <template #body="{ data }">
                        <i class="pi" :class="{ 'pi-box text-green-500': data.ups_package_status === 'Delivered', 'pi-box text-orange-400': data.ups_package_status === 'In Transit', 'pi-box text-red-400': data.ups_package_status === 'Not Send', 'pi-exclamation-triangle text-blue-400': data.ups_package_status === 'Delayed (External factors)', 'pi-box text-green-200': data.ups_package_status === 'Available for pickup', 'pi-exclamation-circle text-white-100': data.ups_package_status === 'Info Received' }"></i>
                        <span v-if="data.ups_package_status" class="ml-2">{{ data.ups_package_status }}</span>
                    </template>
                </Column>

                <Column field="price_gross" header="Price" bodyStyle="font-size: 14px;" style="max-width: 1rem">
                    <template #body="{ data }">
                        <span>{{ data.price_gross }}</span>
                    </template>
                </Column>

                <Column field="ups_shipment_price" header="UPS" bodyStyle="font-size: 14px;" style="max-width: 1rem">
                    <template #body="{ data }">
                        <span>{{ returnUpsPrice(data.ups_shipment_price, data.infos) }}</span>
                    </template>
                </Column>

                <Column field="fees" header="Fees/A" bodyStyle="font-size: 14px;" style="max-width: 1rem">
                    <template #body="{ data }">
                        <span>{{ returnTransactionFees(data) }}</span>
                    </template>
                </Column>

                <Column field="fees_payment" header="F. payment" bodyStyle="font-size: 14px;" style="max-width: 1rem">
                    <template #body="{ data }">
                        <span>{{ returnPaymentFees(data) }}</span>
                    </template>
                </Column>

                <Column field="delivered_at" header="Delivered" bodyStyle="font-size: 14px;" dataType="boolean" style="max-width: 1rem">
                    <template #body="{ data }">
                        <span v-if="data.ups_package_status">{{ convertDateDelivered(data.delivered_at) }}</span>
                        <span v-else>-</span>
                    </template>
                </Column>

                <Column headerStyle="width: 5rem; text-align: center" bodyStyle="font-size: 14px;">
                    <template #body="{ data }">
                        <a v-if="data.ups_tracking" :href="'https://www.ups.com/track?tracknum=' + data.ups_tracking" target="_blank" rel="noopener noreferrer" class="mr-2">
                            Track
                        </a>

                        <a :href="data.invoice_url" target="_blank" rel="noopener noreferrer" class="mr-2">
                            Invoice
                        </a>

                        <a href="#">
                            Details
                        </a>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>  
import { mapActions, mapGetters } from 'vuex';
import { FilterMatchMode } from 'primevue/api';
import m from 'moment';
import { find, filter } from 'lodash';

export default {
    name: 'statisticsBoard',
    components: {
    },
    data() {
        return {
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            activeStatisticsTab: 0,
            items: [
                { label: 'Transactions', icon: 'pi pi-list' },
                { label: 'UPS', icon: 'pi pi-list' },
            ],
            transactionsReport: {
                start: null,
                end: null
            }
        };
    },
    mounted() {
        this.transactions();
    },
    computed: {
        ...mapGetters({
            getTransactions: 'getTransactions'
        }),
        filterTransactions() {
            return filter(this.getTransactions, (o) => {                
                return o.linked_invoice_id === null;
            })
        }
    },
    methods: {
        ...mapActions({
            transactions: 'transactions',
            user: 'user',
            userStatistics: 'userStatistics',
            getDownloadTransactionsXLSX: 'getDownloadTransactionsXLSX'
        }),
        convertDate(data) {
            return m(data.created_at).format('DD/MM/YYYY HH:mm');
        },
        returnOrderType(orderType) {
            if (orderType === 1) {
                return 'SELL';
            } else if(orderType === 4) {
                return 'BT';
            } else {
                return 'SUB/KYC';
            }
        },
        returnUpsPrice(priceUPS, infos) {
            if (priceUPS) {

                const findUps = find(infos, (o) => {
                    return o.name === "Frais d'expédition UPS";
                })

                if (priceUPS.TotalChargesWithTaxes) {

                    if (findUps) {
                        return `${findUps.total_price_gross} (${priceUPS.TotalChargesWithTaxes.MonetaryValue})`;
                    } else {
                        return priceUPS.TotalChargesWithTaxes.MonetaryValue;
                    }
                } else {
                    return 'N/A';
                }
                
            } else {
                return '-';
            }
        },
        returnTransactionFees(data) {
            let totalFees = 0;

            const findFeesTEP = find(data.infos, (o) => {
                if (o.name) {
                    return o.name.indexOf('Commission') !== -1;
                }
            })

            const findFeesTEPSellerTransaction = find(this.getTransactions, (o) => {
                return o.linked_invoice_id === data.invoice_id;
            })            

            if (findFeesTEPSellerTransaction) {
                const findFeesTEPSeller = find(findFeesTEPSellerTransaction.infos, (o) => {                    
                    if (o.name) {
                        return o.name.indexOf('Commission') !== -1;
                    }
                })

                const findAnonymousTransaction = find(findFeesTEPSellerTransaction.infos, (o) => {  
                    if (o.name) {
                        return o.name.indexOf('Anonymisation') !== -1;
                    }              
                })

                if (findFeesTEP && findFeesTEPSeller && !findAnonymousTransaction) {
                    totalFees += findFeesTEP.total_price_gross;
                    totalFees += Math.abs(findFeesTEPSeller.total_price_gross);                    
                    return `${totalFees}`;
                } else if (findFeesTEP && findFeesTEPSeller && findAnonymousTransaction) {
                    totalFees += findFeesTEP.total_price_gross;
                    totalFees += Math.abs(findFeesTEPSeller.total_price_gross); 
                    // totalFees += findAnonymousTransaction.total_price_gross;
                    return `${totalFees} (+${Math.abs(findAnonymousTransaction.total_price_gross)})`;
                } else {
                    return '-';
                }
            }
        },
        returnIdentity(data) {
            let identity = '';

            if (data.firstname) {
                identity += data.firstname.toUpperCase();
            }
            if (data.lastname) {
                identity += ' ' + data.lastname.slice(0, 1).toUpperCase() + '.';
            }

            return identity;
        },
        convertDateDelivered(date) {
            if (date) {
                return m(date).format('DD/MM/YYYY HH:mm');
            }

            return '-';
        },
        returnSellerIdentity(data) {            
            const findSeller = find(this.getTransactions, { linked_invoice_id: data });
            
            if (findSeller) {
                if (findSeller.firstname && findSeller.lastname) {
                    return `${findSeller.firstname.toUpperCase()} ${findSeller.lastname.slice(0, 1).toUpperCase()}.`
                } else {
                    return '-'
                }
            }

        },
        returnPaymentFees(data) {
            let totalFees = 0;
               
            const findFeesTEPBuyer = find(data.infos, (o) => {                    
                if (o.name) {
                    return o.name.indexOf('paiement') !== -1;
                }
            })

            if (findFeesTEPBuyer) {
                console.log('find');
                
                totalFees += findFeesTEPBuyer.total_price_gross;
                return `${totalFees}`;
            } else {
                return '-';
            }
        },
        async redirectToUser(data) {
            await this.user(data.user_uuid).then(async() => {
                this.userStatistics(data.user_uuid).then(() => {
                    this.$router.push('/dashboard/user/' + data.user_uuid);
                })
            })
        },
        async redirectToUserSeller(data) {
            const findSeller = find(this.getTransactions, { linked_invoice_id: data.invoice_id });
            
            if (findSeller) {
                await this.user(findSeller.user_uuid).then(() => {
                    this.$router.push('/dashboard/user/' + findSeller.user_uuid);
                })
            }
        },
        dowloadTransactionsXLSX() {
            this.getDownloadTransactionsXLSX(this.transactionsReport);
        }
    },
};
</script>