<template>
    <div class="m-center surface-card p-4 shadow-2 border-round w-full lg:w-6">
        <div>
            <label for="code" class="block text-900 font-medium mb-2">Code</label>
            <InputText id="code" type="text" placeholder="Code" class="w-full mb-3" v-model="payload.code" />

            <Button @click="sendCodeDoubleFA()" label="Sign in" icon="pi pi-user" class="w-full"></Button>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'doubleFA',
    data() {
        return {
            payload: {
                code: null
            }
        };
    },
    computed: {
        ...mapGetters({
            getUser: 'getUser'
        }),
    },
    methods: {
        ...mapActions({
            doubleFA: 'doubleFA',
            missingBottles: 'missingBottles',
            errorsBottles: 'errorsBottles',
            historyBrowser: 'historyBrowser',
            historyProducts: 'historyProducts'
        }),
        async sendCodeDoubleFA() {
            await this.doubleFA(this.payload).then(() => {
                this.missingBottles();
                this.errorsBottles();
                this.historyBrowser();
                this.historyProducts();
            });
        }
    }
};
</script>