<template>
    <div>
        <div class="flex w-12">
            <div class="flex flex-column gap-2 w-4">
                <label for="name">Name</label>
                <InputText id="name" v-model="bottle.title" placeholder="-" disabled />
            </div>

            <div class="flex flex-column gap-2 ml-2 w-4">
                <label for="storage">Storage origin</label>
                <Dropdown v-model="bottle.storage_origin" :options="storageOrigin" optionLabel="name" placeholder="Select an origin" />
            </div>

            <div class="flex flex-column gap-2 ml-2 w-4">
                <label for="ticker">Ticker <span class="pi pi-search ml-1" style="font-size: .7rem"></span></label>
                <AutoComplete v-model="bottle.ticker" optionLabel="ticker" :suggestions="getSearchTickers" @complete="searchByTickers" placeholder="Search with ticker..." />
            </div>
        </div>

        <div class="flex mt-3 w-12">
            <div class="flex flex-column gap-2 w-3">
                <label for="order">Order</label>
                <InputText id="order" v-model="bottle.order_uuid" :disabled='!bottle.user_uuid' placeholder="Order uuid" />
            </div>

            <div class="flex flex-column gap-2 ml-2 w-3">
                <label for="portfolio">Portfolio bottle</label>
                <!-- <InputText id="portfolio" v-model="bottle.portfolio_bottle_uuid" :disabled='!bottle.user_uuid' placeholder="Bottle uuid" /> -->
                <AutoComplete v-model="bottle.portfolio_bottle_uuid" optionLabel="ticker" dropdown :disabled='!bottle.user_uuid || !bottle.ticker' :suggestions="getSearchPortfolioBottles">
                    <template #option="slotProps">
                        <div class="flex items-center">
                            <div>{{ slotProps.ticker }}, {{ slotProps.price }} EUR at </div>
                        </div>
                    </template>
                </AutoComplete>
            </div>

            <div class="flex flex-column gap-2 ml-2 w-3">
                <label for="invoice">Invoice</label>
                <InputText id="invoice" v-model="bottle.invoice_uuid" :disabled='!bottle.user_uuid' placeholder="Invoice uuid" />
            </div>

            <div class="flex flex-column gap-2 ml-2 w-3">
                <label for="email">User <span class="pi pi-search ml-1" style="font-size: .7rem"></span></label>
                <AutoComplete v-model="bottle.user_uuid" optionLabel="email" :suggestions="getSearchUsers" @complete="searchByUsers" placeholder="Search with email..." />
            </div>
        </div>

        <div class="flex mt-3 w-12">
            <div class="flex flex-column gap-2 w-6">
                <label for="renew_date">Storage at :</label>
                <Calendar v-model="bottle.renew_date" dateFormat="dd/mm/yy" placeholder="Storage start at..." />
            </div>

            <div class="flex flex-column gap-2 ml-2 w-6">
                <label for="end_date">Storage end :</label>
                <Calendar v-model="bottle.end_date" dateFormat="dd/mm/yy" placeholder="Storage ending at..." />
            </div>
        </div>

        <Button
            label="Create bottle (storage)"
            severity="success"
            class="mt-4"
            @click="create()"
            :disabled="!bottle.user_uuid || !bottle.storage_origin || !bottle.ticker || !bottle.title"
        />

        <Toast />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import { FilterMatchMode } from 'primevue/api';
// import m from 'moment';

export default {
    name: 'storageCreateBottle',
    data() {
        return {
            bottle: {
                user_uuid: null,
                order_uuid: null,
                portfolio_bottle_uuid: null,
                invoice_uuid: null,
                storage_origin: null,
                storage_at: null,
                exit_at: null,
                ticker: null,
                title: null
            },
            storageOrigin: [
                { name: 'TEP to Storage', code: 'TEP' },
                { name: 'Manual deposit', code: 'MD_TO_TEP' },
                { name: 'Package send to TEP', code: 'PACKAGE_TO_TEP' },
                { name: 'Other', code: 'OTHER' },
            ]
        };
    },
    watch: {
        'bottle.ticker' : function() {
            if (this.bottle.ticker) {
                this.bottle.title = this.bottle.ticker.title;

                if (this.bottle.user_uuid) {
                    this.searchByPortfolioBottles();
                }
            } else {
                this.bottle.title = null;
            }
        },
        'bottle.user_uuid': function() {
            if (this.bottle.user_uuid && this.bottle.ticker) {
                this.searchByPortfolioBottles();
            }
        }
    },
    computed: {
        ...mapGetters({
            getSearchUsers: 'getSearchUsers',
            getSearchTickers: 'getSearchTickers',
            getSearchPortfolioBottles: 'getSearchPortfolioBottles'
        }),
    },
    methods: {
        ...mapActions({
            searchUsers: 'searchUsers',
            searchTickers: 'searchTickers',
            searchPortfolioBottles: 'searchPortfolioBottles',
            addBottleToStorage: 'addBottleToStorage'
        }),
        searchByUsers(event) {
            const payload = {
                search: event.query
            }

            this.searchUsers(payload);
        },
        searchByTickers(event) {
            const payload = {
                search: event.query
            }

            this.searchTickers(payload);
        },
        searchByPortfolioBottles() {
            const payload = {
                user_uuid: this.bottle.user_uuid.uuid || '',
                ticker: this.bottle.ticker.ticker || ''
            }

            if (payload.user_uuid.length > 30 && payload.ticker.length > 4) {
                this.searchPortfolioBottles(payload);
            }
        },
        async create() {
            await this.addBottleToStorage(this.bottle).then(() => {
                this.bottle = {
                    user_uuid: null,
                    order_uuid: null,
                    portfolio_bottle_uuid: null,
                    invoice_uuid: null,
                    storage_origin: null,
                    storage_at: null,
                    exit_at: null,
                    ticker: null,
                    title: null
                }

                this.$toast.add({ severity: 'success', summary: `Bottle to storage has created`, detail: 'Create OK', life: 3000 });

            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: `Error to create bottle storage. Contact supprot team`, detail: 'Create error', life: 3000 });
            })
        }
    },
};
</script>