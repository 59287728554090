<template>
    <div class="card lg:px-9">
        <TabMenu v-model:activeIndex="active" :model="items" class="mt-2" />

        <div v-if="active === 0">
            <DataTable :value="getUnverifiedOrders" stripedRows paginator :rows="50" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" class="mt-2" tableStyle="min-width: 50rem">

                <Column field="created_at" header="Created" style="max-width: 2rem">
                    <template #body="{ data }">
                        <p>{{ convertDateDay(data.created_at) || '-' }}</p>
                    </template>
                </Column>

                <Column field="countdown" header="End" style="max-width: 2rem">
                    <template #body="{ data }">
                        <p>{{ convertDateDay(data.countdown) || '-' }}</p>
                    </template>
                </Column>

                <Column field="isbn" header="ISBN" style="max-width: 1rem"></Column>

                <Column field="order_type" header="Type" style="max-width: 1rem">
                    <template #body="{ data }">
                        <p>{{ orderType(data.order_type) || '-' }}</p>
                    </template>
                </Column>

                <Column field="price" header="€" style="max-width: 1rem">
                    <template #body="{ data }">
                        <p>{{ data.price / 100 }}</p>
                    </template>
                </Column>


                <Column field="enabled" header="Enabled" dataType="boolean" style="max-width: 1rem">
                    <template #body="{ data }">
                        <i class="pi" :class="{ 'pi-check-circle text-green-500': data.enabled, 'pi-times-circle text-red-400': !data.enabled }"></i>
                    </template>
                </Column>

                <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <Button v-if="data.order_uuid" @click="openOrderUUID = data" @close="openOrderUUID = null" type="button" icon="pi pi-eye" rounded />
                    </template>
                </Column>

            </DataTable>
        </div>
        <div v-if="active === 1">
            <DataTable v-model:filters="filters" :value="filterOrders" stripedRows paginator :rows="50" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" class="mt-2" tableStyle="min-width: 50rem">
                <template #header>
                <div class="flex justify-content-end align-items-center">
                    <InputSwitch :trueValue="1" :falseValue="0" v-model="seeEnabledOrders" class="mr-3" />

                    <Dropdown v-model="selectedOrderType" :options="orderTypeOptions" optionLabel="label" placeholder="Select a order type" class="w-full md:w-14rem mr-3" />

                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    </span>
                </div>
            </template>
            <template #empty> No orders found. </template>

                <Column field="created_at" header="Created" style="max-width: 2rem">
                    <template #body="{ data }">
                        <p>{{ convertDateDay(data.created_at) || '-' }}</p>
                    </template>
                </Column>

                <Column field="countdown" header="End" style="max-width: 2rem">
                    <template #body="{ data }">
                        <p>{{ convertDateDay(data.countdown) || '-' }}</p>
                    </template>
                </Column>

                <Column field="isbn" header="ISBN" style="max-width: 1rem"></Column>

                <Column field="order_type" header="Type" style="max-width: 1rem">
                    <template #body="{ data }">
                        <p>{{ orderType(data.order_type) || '-' }}</p>
                    </template>
                </Column>

                <Column field="price" header="€" style="max-width: 1rem">
                    <template #body="{ data }">
                        <p>{{ data.price / 100 }}</p>
                    </template>
                </Column>

                <Column field="is_pretrade" header="Pretrade" dataType="boolean" style="max-width: 1rem">
                    <template #body="{ data }">
                        <i class="pi" :class="{ 'pi-check-circle text-green-500': data.is_pretrade, 'pi-times-circle text-red-400': !data.is_pretrade }"></i>
                    </template>
                </Column>

                <Column field="sold" header="Sold" dataType="boolean" style="max-width: 1rem">
                    <template #body="{ data }">
                        <i class="pi" :class="{ 'pi-check-circle text-green-500': data.sold, 'pi-times-circle text-red-400': !data.sold }"></i>
                    </template>
                </Column>

                <Column field="decline_reasons" header="Invalid ?" dataType="boolean" style="max-width: 1rem">
                    <template #body="{ data }">
                        <i class="pi" :class="{ 'pi-check-circle text-green-500': data.decline_reasons, 'pi-times-circle text-red-400': !data.decline_reasons }"></i>
                    </template>
                </Column>

                <Column field="enabled" header="Enabled" dataType="boolean" style="max-width: 1rem">
                    <template #body="{ data }">
                        <i class="pi" :class="{ 'pi-check-circle text-green-500': data.enabled, 'pi-times-circle text-red-400': !data.enabled }"></i>
                    </template>
                </Column>

                <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                    <template #body="{ data }">
                        <Button v-if="data.order_uuid" @click="openOrderUUID = data" type="button" icon="pi pi-eye" rounded />
                    </template>
                </Column>

            </DataTable>
        </div>

        <previewVue :openOrderUUID="openOrderUUID" @close="openOrderUUID = null" />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import previewVue from '@/components/dashboard/modules/orders/preview.vue';
import m from 'moment';
import { FilterMatchMode } from 'primevue/api';
import { filter } from 'lodash';

export default {
    name: 'ordersList',
    components: {
        previewVue
    },
    data() {
        return {
            active: 0,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            items: [
                { label: 'Orders must be check', icon: 'pi pi-exclamation-triangle' },
                { label: 'Orders list', icon: 'pi pi-list' }
            ],
            openOrderUUID: null,
            selectedOrderType: { label: 'Pas de filtres', code: 'NF' },
            seeEnabledOrders: 1,
            orderTypeOptions: [
                { label: 'Pas de filtres', code: 'NF' },
                { label: 'Buy', code: 0 },
                { label: 'Sell', code: 1 },
                { label: 'Blocktrade', code: 2 },
                { label: 'Giver', code: 3 },
                { label: 'Taker', code: 4 }
            ]
        };
    },
    computed: {
        ...mapGetters({
            getVerifiedOrders: 'getVerifiedOrders',
            getUnverifiedOrders: 'getUnverifiedOrders'
        }),
        filterOrders() {
            if (this.selectedOrderType.code === 'NF') {
                return filter(this.getVerifiedOrders, (o) => o.enabled === this.seeEnabledOrders);
            } else {
                return filter(this.getVerifiedOrders, (o) => o.order_type === this.selectedOrderType.code && o.enabled === this.seeEnabledOrders);
            }
        }
    },
    methods: {
        ...mapActions({
            verified: 'verifiedOrders',
            unverified: 'unverifiedOrders'
        }),
        convertDateDay(data) {
            const convert = m(data).format('DD/MM/YYYY HH:mm:ss');

            if (convert === 'Invalid date') {
                return '-';
            }
            return convert;
        },
        orderType(type) {
            if (type === 0) {
                return 'BUY';
            } else if (type === 1) {
                return 'SELL';
            } else if (type === 2) {
                return 'SG';
            } else if (type === 3) {
                return 'ST';
            } else if (type === 4) {
                return 'BT';
            } else {
                return '-';
            }
        }
    },
    mounted() {
        this.verified();
        this.unverified();
    }
};
</script>