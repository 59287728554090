<template>
    <div class="card lg:px-9">
        <DataTable :value="getScrapingCategories" stripedRows paginator :rows="50" :rowsPerPageOptions="[5, 10, 20, 50]" tableStyle="min-width: 50rem">
            <Column field="spirit_type" header="Code" style="max-width: 5rem"></Column>
            <Column field="label" header="Label" style="max-width: 5rem"></Column>
            <Column field="nb" header="Count" style="max-width: 5rem"></Column>

            <!-- <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <a :href="data.url" target="_blank" rel="noopener noreferrer">
                        <Button type="button" icon="pi pi-window-maximize" rounded />
                    </a>
                </template>
            </Column> -->
        </DataTable>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'scrapingStatistics',
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            getScrapingCategories: 'getScrapingCategories'
        }),
    },
    methods: {
        ...mapActions({
            scrapingStatistics: 'scrapingStatistics'
        }),
    },
    mounted() {
        this.scrapingStatistics();
    }
};
</script>