<template>
    <div class="card lg:px-9">
        <DataTable :value="getAutoscraper" stripedRows paginator :rows="50" :rowsPerPageOptions="[5, 10, 20, 50, 100]" tableStyle="min-width: 50rem">
            <Column field="code" header="Code" style="max-width: 5rem"></Column>
            <Column field="last_check" header="Last check" style="max-width: 5rem">
                <template #body="{ data }">
                    <span>{{ convertDate(data.last_check) }}</span>
                </template>
            </Column>
            <Column field="last_integrate" header="Last integrate" style="max-width: 5rem">
                <template #body="{ data }">
                    <span>{{ convertDate(data.last_integrate) }}</span>
                </template>
            </Column>

            <Column field="pictures_protected" header="IMG get" dataType="boolean" style="max-width: 5rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': !data.pictures_protected, 'pi-times-circle text-red-400': data.pictures_protected }"></i>
                </template>
            </Column>

            <Column field="pictures_protected_cause" header="Cause" style="max-width: 8rem"></Column>

            <Column field="get_pictures" header="IMG Script" dataType="boolean" style="max-width: 3rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': data.get_pictures, 'pi-times-circle text-red-400': !data.get_pictures }"></i>
                </template>
            </Column>

            <Column field="enabled" header="Enabled" dataType="boolean" style="max-width: 3rem">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'pi-check-circle text-green-500': data.enabled, 'pi-times-circle text-red-400': !data.enabled }"></i>
                </template>
            </Column>

            <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <a :href="data.url" target="_blank" rel="noopener noreferrer">
                        <Button type="button" icon="pi pi-window-maximize" rounded />
                    </a>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import m from 'moment';

export default {
    name: 'autoScraper',
    data() {
        return {
        };
    },
    computed: {
        ...mapGetters({
            getAutoscraper: 'getAutoscraper'
        }),
    },
    methods: {
        ...mapActions({
            autoscraperStatistics: 'autoscraperStatistics'
        }),
        convertDate(date) {
            if (date) {  
                return m(date).format('DD/MM/YYYY');
            }
            return '-';
        }
    },
    mounted() {
        this.autoscraperStatistics('autoscraper');
    }
};
</script>