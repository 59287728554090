import axios from 'axios';
// import Store from '@/store/index';
import Router from '@/router/index';

if (process.env.NODE_ENV === 'production') {
  axios.defaults.withCredentials = true;
}

/* Define instance - set api url path */
const apiInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

/* Intercept request */
apiInstance.interceptors.request.use(
  async (config) => {
    const jwtTokenRegex = /^eyJ[a-zA-Z0-9_-]+?\.[a-zA-Z0-9_-]+?(\.[a-zA-Z0-9_-]+)?$/;

    const accessToken = await getAccessToken();
    if (jwtTokenRegex.test(accessToken)) {
      console.log('Set token');
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/* Intercept response */
apiInstance.interceptors.response.use(  
  async (response) => {


    if (response.data.message) {
        console.log('set message');
    }
    return response;
  },
  (error) => {
    if (error.response.status === 301) {

        console.log('set message');

    } else if (error.response.status === 400) {
        console.log('set message');
    } else if (error.response.status === 401) {
        console.log('set message');

      localStorage.removeItem('token');
    //   Store.commit('SET_AUTH', false);
      Router.push('/');
    } else if (error.response.status === 403) {
        console.log('set message');
    } else if (error.response.status === 406) {
        console.log('set message');
    } else if (error.response.status === 429) {
        console.log('set message');
    } else if (error.response.status === 500) {
        console.log('set message');
    }
    return Promise.reject(error);
  }
);

/* Get token */
const getAccessToken = async () => {
  let accessToken = '';

  if (process.env.NODE_ENV !== 'production') {
    console.warn('Get token in LS - dev/preprod mode');
    let tokenLS = localStorage.getItem('token');
  
    if (tokenLS) {
      accessToken = tokenLS;
    } else {
      accessToken = '';
    }
  }

  return accessToken.replace('undefined', '');
};

export const api = apiInstance;