<template>
    <div class="card lg:px-9 mt-3">
        <div class="card">
            <TabMenu v-model:activeIndex="active" :model="items" class="mb-4" />

            <ProgressBar :value="stepPercent" style="height: 15px">{{ value }}%</ProgressBar>

            <div v-if="active === 0 && getUser" class="mt-2">
                <div style="height: 20%" class="border-2 border-dashed surface-border mb-2">
                    <div class="mx-3 mt-2">
                        <i class="pi" :class="{ 'pi-user': !getUser.account_type, 'pi-building': getUser.account_type }"></i> User informations (owner account)
                    </div>
                    <div v-if="getUser" class="inputs-row">
                        <span class="p-float-label input-infos">
                            <InputText id="civility" :disabled="!canEditUser" v-model="getUser.civility" />
                            <label for="civility">Civility</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="firstname" :disabled="!canEditUser" v-model="getUser.firstname" />
                            <label for="firstname">Firstname</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="lastname" :disabled="!canEditUser" v-model="getUser.lastname" />
                            <label for="lastname">Lastname</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="birthdate" :disabled="!canEditUser" v-model="getUser.birthdate" />
                            <label for="birthdate">Birthdate</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="nationality" :disabled="!canEditUser" v-model="getUser.nationality" />
                            <label for="nationality">Nationality</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="country" :disabled="!canEditUser" v-model="getUser.country" />
                            <label for="country">Country</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="city" :disabled="!canEditUser" v-model="getUser.city" />
                            <label for="city">City</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="zip" :disabled="!canEditUser" v-model="getUser.zip" />
                            <label for="zip">Zip</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="address" :disabled="!canEditUser" v-model="getUser.address" />
                            <label for="address">Address</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="email" :disabled="!canEditUser" v-model="getUser.email" />
                            <label for="email">Email</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="phone-country" :disabled="!canEditUser" v-model="getUser.phone_country_code" />
                            <label for="phone-country">Phone country code</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="phone" :disabled="!canEditUser" v-model="getUser.phone" />
                            <label for="phone">Phone</label>
                        </span>
                    </div>
                </div>
                <div v-if="getUser.organization" style="height: 20%" class="border-2 border-dashed surface-border mb-2">
                    <div class="mx-3 mt-2">
                        Organization
                    </div>
                    <div v-if="getUser" class="inputs-row">
                        <span class="p-float-label input-infos">
                            <InputText id="organization-name" :disabled="!canEditUser" v-model="getUser.organization.name" />
                            <label for="organization-name">Name</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="organization-siret" :disabled="!canEditUser" v-model="getUser.organization.siret" />
                            <label for="organization-siret">Siret</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="organization-status" :disabled="!canEditUser" v-model="getUser.organization.status" />
                            <label for="organization-status">Status</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="organization-type" :disabled="!canEditUser" v-model="getUser.organization.organization_type" />
                            <label for="organization-type">Type</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="organization-business-type" :disabled="!canEditUser" v-model="getUser.organization.business_type" />
                            <label for="organization-business-type">Category</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="organization-vat" :disabled="!canEditUser" v-model="getUser.organization.vat" />
                            <label for="organization-vat">VAT</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="organization-country" :disabled="!canEditUser" v-model="getUser.organization.country" />
                            <label for="organization-country">Country</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="organization-city" :disabled="!canEditUser" v-model="getUser.organization.city" />
                            <label for="organization-city">City</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="organization-zip" :disabled="!canEditUser" v-model="getUser.organization.zip" />
                            <label for="organization-zip">Zip</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="organization-address" :disabled="!canEditUser" v-model="getUser.organization.address" />
                            <label for="organization-address">Address</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="organization-email" :disabled="!canEditUser" v-model="getUser.organization.email" />
                            <label for="organization-email">Email</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="organization-phone-country" :disabled="!canEditUser" v-model="getUser.phone_country_code" />
                            <label for="organization-phone-country">Phone country code</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="organization-phone" :disabled="!canEditUser" v-model="getUser.phone" />
                            <label for="organization-phone">Phone</label>
                        </span>
                    </div>
                </div>
                <div v-if="getUser.other_delivery_address" style="height: 20%" class="border-2 border-dashed surface-border mb-2">
                    <div class="mx-3 mt-2">
                        Other delivery address
                    </div>
                    <div class="inputs-row">
                        <span class="p-float-label input-infos">
                            <InputText id="country" :disabled="!canEditUser" v-model="getUser.other_delivery_address.country" />
                            <label for="country">Country</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="city" :disabled="!canEditUser" v-model="getUser.other_delivery_address.city" />
                            <label for="city">City</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="zip" :disabled="!canEditUser" v-model="getUser.other_delivery_address.zip" />
                            <label for="zip">Zip</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="address" :disabled="!canEditUser" v-model="getUser.other_delivery_address.address" />
                            <label for="address">Address</label>
                        </span>
                    </div>
                </div>
                <div v-for="shareholder, n in getUser.shareholders" :key="n" style="height: 20%" class="border-2 border-dashed surface-border mb-2">
                    <div class="mx-3 mt-2">
                        Shareholders
                    </div>
                    <div v-if="getUser" class="inputs-row">
                        <span class="p-float-label input-infos">
                            <InputText id="shareholder-civility" :disabled="!canEditUser" v-model="shareholder.civility" />
                            <label for="shareholder-civility">Civility</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="shareholder-firstname" :disabled="!canEditUser" v-model="shareholder.firstname" />
                            <label for="shareholder-firstname">Firstname</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="shareholder-lastname" :disabled="!canEditUser" v-model="shareholder.lastname" />
                            <label for="shareholder-lastname">Lastname</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="shareholder-birth" :disabled="!canEditUser" v-model="shareholder.birth" />
                            <label for="shareholder-birth">Birthdate</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="shareholder-nationality" :disabled="!canEditUser" v-model="shareholder.nationality" />
                            <label for="shareholder-nationality">Nationality</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="shareholder-country" :disabled="!canEditUser" v-model="shareholder.country" />
                            <label for="shareholder-country">Country</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="shareholder-city" :disabled="!canEditUser" v-model="shareholder.city" />
                            <label for="shareholder-city">City</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="shareholder-zip" :disabled="!canEditUser" v-model="shareholder.zip" />
                            <label for="shareholder-zip">Zip</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="shareholder-address" :disabled="!canEditUser" v-model="shareholder.address" />
                            <label for="shareholder-address">Address</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="shareholder-email" :disabled="!canEditUser" v-model="shareholder.email" />
                            <label for="shareholder-email">Email</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="shareholder-phone-country" :disabled="!canEditUser" v-model="shareholder.phone_country" />
                            <label for="shareholder-phone-country">Phone country code</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="shareholder-phone" :disabled="!canEditUser" v-model="shareholder.phone" />
                            <label for="shareholder-phone">Phone</label>
                        </span>
                    </div>
                </div>
                <div style="height: 20%" class="border-2 border-dashed surface-border mb-2">
                    <div class="mx-3 mt-2">
                        Others configuration
                    </div>
                    <div class="inputs-row">
                        <span class="p-float-label input-infos">
                            <InputText id="discount" :disabled="!canEditUser" v-model="getUser.discount" />
                            <label for="discount">Discount (%)</label>
                        </span>
                        <span class="p-float-label input-infos">
                            <InputText id="discount-expiration" :disabled="!canEditUser" v-model="getUser.discount_expiration" />
                            <label for="discount-expiration">Discount expiration</label>
                        </span>
                    </div>
                </div>
                <div style="height: 20%" class="border-2 border-dashed surface-border mb-2">
                    <div class="mx-3 mt-2">
                        Global informations
                    </div>
                    <div v-if="getUser" class="inputs-row ml-2">
                        <div class="font-medium text-500 mb-3">Created date: {{ convertDate(getUser.created_at) }}</div>
                        <div class="font-medium text-500 mb-3 ml-5">Created by: {{ getUser.created_by }}</div>
                        <div v-if="getUser.organization" class="font-medium text-500 mb-3 ml-5">KYC payin ID (Mangopay): {{ getUser.kyc_payin_id || 'N/A' }}</div>
                        <span class="input-infos mt-0 mb-0 ml-3">
                            <label for="newsletter">Newsletter ({{ getUser.newsletter || 'N/A' }}): </label>
                            <InputSwitch v-model="getUser.newsletter" :disabled="!canEditUser" :true-value="1" :false-value="0" inputId="newsletter" />
                        </span>
                    </div>
                </div>
            </div>
            
            <div v-if="active === 1 && getUser" class="flex flex-wrap">
                <Card style="width: 25em" class="mx-2 my-2">
                    <template #title>Email <i v-if="getUser.email_is_verified" class="pi pi-check-circle ml-2" style="color: green"></i></template>
                    <template #subtitle>{{ convertDate(getUser.email_is_verified_date) }}</template>
                    <template #content>
                        <p class="m-0">
                            {{ getUser.email }}
                        </p>
                    </template>
                    <template #footer>
                        <Button @click="updateUserVerification('email', 1)" v-if="!getUser.email_is_verified" icon="pi pi-check" label="Check email" severity="success" />
                        <Button @click="updateUserVerification('email', 0)" v-else icon="pi pi-times" label="Cancel verification" severity="danger" />
                    </template>
                </Card>
                <Card style="width: 25em" class="mx-2 my-2">
                    <template #title>Phone <i v-if="getUser.phone_is_verified" class="pi pi-check-circle ml-2" style="color: green"></i></template>
                    <template #subtitle>{{ convertDate(getUser.phone_is_verified_date) }}</template>
                    <template #content>
                        <p class="m-0">
                            +{{ getUser.phone_country_code }} {{ getUser.phone }} ({{ getUser.country }})
                        </p>
                    </template>
                    <template #footer>
                        <Button @click="updateUserVerification('phone', 1)" v-if="!getUser.phone_is_verified" icon="pi pi-check" label="Check phone" severity="success" />
                        <Button @click="updateUserVerification('phone', 0)" v-else icon="pi pi-times" label="Cancel verification" severity="danger" />
                    </template>
                </Card>
                <Card style="width: 25em" class="mx-2 my-2">
                    <template #title>KYC 
                        <i v-if="getUser.kyc_is_verified" class="pi pi-check-circle ml-2" style="color: green"></i>
                        <i v-if="getUser.kyc_verification_pending" class="pi pi-hourglass ml-2" style="color: orange"></i>
                    </template>
                    <template #subtitle>{{ convertDate(getUser.kyc_is_verified_date) }}</template>
                    <template #content>
                        <div class="m-0">
                            <p v-if="getUser.kyc_payin_id">KYC payin ID: {{ getUser.kyc_payin_id }}</p>
                            <p v-if="getUser.kyc_payment_date">KYC payment date: {{ convertDate(getUser.kyc_payment_date) }}</p>
                        </div>
                    </template>
                    <template #footer>
                        <Button v-if="!getUser.kyc_is_verified" icon="pi pi-check" disabled label="Check KYC" severity="success" />
                        <Button v-else icon="pi pi-times" disabled label="Cancel verification" severity="danger" />
                    </template>
                </Card>
            </div>

            <div v-if="active === 2 && getUser">
                <ordersVue :orders="getUser.orders" />
            </div>

            <div v-if="active === 3 && getUser" class="mt-2">

                <div v-if="getUser.subscriptions_informations.length > 0" class="card flex align-items-center mt-2 mb-2">
                    <Dropdown v-model="typeSubscriptionGive" :options="subscriptionList" optionLabel="label" placeholder="Subscription type" class="w-full md:w-14rem" />
                    <Calendar v-model="expirationSubscriptionDate" dateFormat="yy-mm-dd" showIcon class="ml-2" />
                    <Button :disabled="!typeSubscriptionGive || !expirationSubscriptionDate" @click="giveSubscription()" class="ml-2" type="button" label="Give subscription" severity="success" />
                </div>

                <Button v-else @click="giveSubscription()" class="mt-2 mb-2" type="button" label="Initialization subscription" severity="success" />

                <DataTable :value="getUser.subscriptions_informations" stripedRows paginator rows="10" tableStyle="min-width: 50rem">

                    <Column field="label" header="label" style="max-width: 4rem"></Column>
                    <Column field="price" header="€" style="max-width: 2rem"></Column>

                    <Column field="created_at" header="Subscribe date" style="max-width: 4rem">
                        <template #body="{ data }">
                            <p>{{ convertDateDay(data.created_at) || '-' }}</p>
                        </template>
                    </Column>

                    <Column field="next_payment" header="Next payment" style="max-width: 4rem">
                        <template #body="{ data }">
                            <p>{{ convertDateDay(data.next_payment) || '-' }}</p>
                        </template>
                    </Column>

                    <Column field="commitment" header="Commitment" style="max-width: 4rem">
                        <template #body="{ data }">
                            <p>{{ convertDateDay(data.commitment) || '-' }}</p>
                        </template>
                    </Column>

                    <Column field="subscription_state" header="Enabled" dataType="boolean" style="max-width: 2rem">
                        <template #body="{ data }">
                            <i class="pi" :class="{ 'pi-check-circle text-green-500': data.subscription_state, 'pi-times-circle text-red-400': !data.subscription_state }"></i>
                        </template>
                    </Column>

                    <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                        <template #body="{ data }">
                            <div class="flex card custom-style">
                                <ConfirmPopup></ConfirmPopup>
                                <Button v-if="data.subscription_state" @click="disabledSubscription($event, data)" type="button" icon="pi pi-times-circle" severity="danger" rounded />
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>

            <div v-if="active === 4 && getUser"></div>

            <div v-if="active === 5 && getUser">
                <DataTable v-model:filters="filters" :value="getUserStatistics.login_history" stripedRows paginator :rows="10" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 70rem" class="mt-2">
                    <template #header>
                        <div class="flex justify-content-end">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span>
                        </div>
                    </template>
                    <template #empty> Not found. </template>

                    <Column field="created_at" header="Date" style="max-width: 3rem">
                        <template #body="{ data }">
                            <span>{{ convertDate(data.login_at) }}</span>
                        </template>
                    </Column>
                    <Column field="ip" header="IP" style="max-width: 3rem">
                        <template #body="{ data }">
                            <span>{{ data.ip.replace('::ffff:', '') }}</span>
                        </template>
                    </Column>
                    <Column field="country" header="Country" style="min-width: 1rem"></Column>
                    <Column field="login_origin" header="Description"></Column>
                </DataTable>

                <DataTable v-model:filters="filters" :value="getUserStatistics.global_history" stripedRows paginator :rows="10" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 70rem" class="mt-2">
                    <template #header>
                        <div class="flex justify-content-end">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span>
                        </div>
                    </template>
                    <template #empty> Not found. </template>

                    <Column field="created_at" header="Date" style="min-width: 1rem">
                        <template #body="{ data }">
                            <span>{{ convertDate(data.created_at) }}</span>
                        </template>
                    </Column>
                    <Column field="ip" header="IP" style="min-width: 2rem">
                        <template #body="{ data }">
                            <span>{{ data.ip.replace('::ffff:', '') }}</span>
                        </template>
                    </Column>
                    <Column field="support" header="Support" style="min-width: 1rem">
                        <template #body="{ data }">
                            <span>{{ data.support === 0 ? 'email': 'phone' }}</span>
                        </template>
                    </Column>
                    <Column field="type" header="Description"></Column>
                </DataTable>

                <DataTable v-model:filters="filters" :value="getUser.user_agents" stripedRows paginator :rows="10" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 70rem" class="mt-2">
                    <template #header>
                        <div class="flex justify-content-end">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span>
                        </div>
                    </template>
                    <template #empty> Not found. </template>

                    <Column field="created_at" header="Date" style="min-width: 1rem">
                        <template #body="{ data }">
                            <span>{{ convertDate(data.created_at) }}</span>
                        </template>
                    </Column>
                    <Column field="ip" header="IP" style="min-width: 2rem">
                        <template #body="{ data }">
                            <span>{{ data.ip.replace('::ffff:', '') }}</span>
                        </template>
                    </Column>
                    <Column field="country" header="Country" style="min-width: 1rem"></Column>
                    <Column field="user_agent" header="Device">
                        <template #body="{ data }">
                            <span>{{ data.user_agent }}</span>
                        </template>
                    </Column>
                </DataTable>

                <DataTable v-model:filters="filters" :value="getUserStatistics.search_history" stripedRows paginator :rows="10" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" tableStyle="min-width: 70rem" class="mt-2">
                    <template #header>
                        <div class="flex justify-content-end">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span>
                        </div>
                    </template>
                    <template #empty> Not found. </template>

                    <Column field="created_at" header="Date" style="min-width: 1rem">
                        <template #body="{ data }">
                            <span>{{ convertDate(data.created_at) }}</span>
                        </template>
                    </Column>
                    <Column field="ip" header="IP" style="min-width: 2rem">
                        <template #body="{ data }">
                            <span>{{ data.ip.replace('::ffff:', '') }}</span>
                        </template>
                    </Column>
                    <Column field="isbn" header="ISBN" style="min-width: 1rem">
                        <template #body="{ data }">
                            <span>{{ data.isbn ? data.isbn : '-' }}</span>
                        </template>
                    </Column>
                    <Column field="type" header="Type" style="min-width: 1rem">
                        <template #body="{ data }">
                            <span>{{ data.type ? data.type : '-' }}</span>
                        </template>
                    </Column>
                    <Column field="search" header="Search">
                        <template #body="{ data }">
                            <span>{{ data.search ? data.search : '-' }}</span>
                        </template>
                    </Column>
                </DataTable>
            </div>

            <div v-if="active === 6 && getUser">
                <DataTable :value="getUser.invoices" stripedRows paginator :rows="50" :rowsPerPageOptions="[10, 25, 50, 100, 250, 500]" class="mt-2" tableStyle="min-width: 50rem">
                    <Column field="created_at" header="Created at" style="max-width: 3rem">
                        <template #body="{ data }">
                            <p>{{ convertDate(data.created_at) || '-' }}</p>
                        </template>
                    </Column>

                    <Column field="invoice_id" header="Invoice ID" style="max-width: 2rem"></Column>

                    <Column field="price_net" header="€ HT" style="max-width: 1rem"></Column>
                    <Column field="price_gross" header="€ TTC" style="max-width: 1rem"></Column>

                    <Column field="order_type" header="Order type" style="max-width: 2rem">
                        <template #body="{ data }">
                            <p>{{ data.order_type || '-' }}</p>
                        </template>
                    </Column>

                    <Column field="ups_tracking" header="UPS tracking" style="max-width: 2rem">
                        <template #body="{ data }">
                            <p>{{ data.ups_tracking || '-' }}</p>
                        </template>
                    </Column>

                    <Column field="status" header="Status" style="max-width: 3rem"></Column>

                    <Column headerStyle="width: 5rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                        <template #body="{ data }">
                            <a :href="data.view_url" target="_blank" rel="noopener noreferrer">
                                <Button type="button" icon="pi pi-file-pdf" rounded />
                            </a>
                        </template>
                    </Column>
                </DataTable>
            </div>

            <div v-if="active === 7 && getUser">
                <div style="height: 20%" class="border-2 border-dashed surface-border mt-2 mb-2 px-2 py-2">
                    <Button disabled label="Send Email code (Account verification)" severity="infos" class="mr-2" />
                    <Button disabled label="Send Email code (2FA)" severity="infos" class="mr-2" />
                    <Button disabled label="Send Phone code" severity="infos" class="mr-2" />
                </div>
                <div style="height: 20%" class="border-2 border-dashed surface-border mt-2 mb-2 px-2 py-2">
                    <Button v-if="getUser.email_is_verified" disabled label="Cancel email verification" severity="warning" class="mr-2" />
                    <Button v-else disabled label="Set email to verify" severity="success" class="mr-2" />

                    <Button v-if="getUser.phone_is_verified" disabled label="Cancel phone verification" severity="warning" class="mr-2" />
                    <Button v-else disabled label="Set phone to verify" severity="success" class="mr-2" />

                    <Button v-if="getUser.kyc_is_verified" disabled label="Cancel KYC verification" severity="warning" class="mr-2" />
                    <Button v-else disabled label="Set KYC to verify" severity="success" class="mr-2" />
                </div>
                <div style="height: 20%" class="border-2 border-dashed surface-border mt-2 mb-2 px-2 py-2">
                    <Button @click="getUser.try_pass_before_login = 0, rights()" :disabled="!canActions" label="Reset login try" severity="warning" class="mr-2" />

                    <Button v-if="!getUser.is_create_orders_blocked" @click="getUser.is_create_orders_blocked = 1, rights()" :disabled="!canActions" label="Ban create orders" severity="danger" class="mr-2" />
                    <Button v-else :disabled="!canActions" @click="getUser.is_create_orders_blocked = 0, rights()" label="Unban create orders" severity="success" class="mr-2" />

                    <Button v-if="!getUser.is_transaction_blocked" @click="getUser.is_transaction_blocked = 1, rights()" :disabled="!canActions" label="Ban transactions" severity="danger" class="mr-2" />
                    <Button v-else :disabled="!canActions" @click="getUser.is_transaction_blocked = 0, rights()" label="Unban transactions" severity="success" class="mr-2" />

                    <Button v-if="!getUser.is_blocked" @click="getUser.is_blocked = 1, rights()" :disabled="!canActions" label="Ban account" severity="danger" class="mr-2" />
                    <Button v-else :disabled="!canActions" @click="getUser.is_blocked = 0, rights()" label="Unban account" severity="success" class="mr-2" />
                
                    <Button v-if="!getUser.ban_ip" @dblclick="banIp = 1, rights()" :disabled="!canActions" label="Ban account + IP (dbl click)" severity="danger" class="mr-2" />
                    <Button v-else disabled label="Unban account" severity="success" class="mr-2" />
                </div>
                <div style="height: 20%" class="border-2 border-dashed surface-border mt-2 mb-2 px-2 py-2">
                    <Button @dblclick="deleteAccount()" :disabled="!canActions" label="Delete account (dbl click)" severity="danger" class="mr-2" />
                </div>

                <Button v-if="!canActions" @click="canActions = true" icon="pi pi-lock-open" label="Unlock" severity="danger" class="mt-5" />
                <Button v-else @click="canActions = false" icon="pi pi-lock" label="Lock" severity="success" class="mt-5" />

            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import m from 'moment';
import { FilterMatchMode } from 'primevue/api';
import ordersVue from '@/components/dashboard/modules/orders/orders.vue';

export default {
    name: 'userDetails',
    components: {
        ordersVue
    },
    data() {
        return {
            active: 0,
            items: [
                { label: 'User', icon: 'pi pi-user' },
                { label: 'Verifications', icon: 'pi pi-check-circle' },
                { label: 'Orders', icon: 'pi pi-list' },
                { label: 'Subscriptions', icon: 'pi pi-euro' },
                { label: 'Trading', icon: 'pi pi-sort-alt' },
                { label: 'History', icon: 'pi pi-search' },
                { label: 'Invoices', icon: 'pi pi-file-pdf' },
                { label: 'Actions', icon: 'pi pi-ellipsis-h' },
            ],
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            canEditUser: false,
            canActions: false,
            banIp: 0,
            typeSubscriptionGive: [],
            expirationSubscriptionDate: null,
            subscriptionList: [
                {
                    label: "Rhums",
                    type: 0
                },
                {
                    label: "Whisky",
                    details: ["Whisky", "Whiskey"],
                    type: 1
                },
                {
                    label: "American Whisky",
                    details: ["Bourbon", "Rye", "American Whisky"],
                    type: 2
                },
                {
                    label: "Eau de vie",
                    type: 3
                },
                {
                    label: "Liqueur & Aperitif",
                    type: 4
                },
                {
                    label: "Agave, Distillate & Various",
                    type: 5
                },
                // {
                //     label: "Portfolio",
                //     type: 7
                // }
            ],
        };
    },
    computed: {
        ...mapGetters({
            getUser: 'getUser',
            getUserStatistics: 'getUserStatistics'
        }),
        stepPercent() {
            let count = 0;

            console.log(this.getUser);
            if (this.getUser?.email_is_verified) {
                count += 25;

                if (this.getUser?.phone_is_verified) {
                    count += 25;

                    if (this.getUser?.kyc_is_verified) {
                        count += 25;

                        if (this.getUser?.banking_is_verified) {
                            count += 25;
                        }
                    }
                }
            }

            return count;
        }
    },
    methods: {
        ...mapActions({
            user: 'user',
            updateUserRights: 'updateUserRights',
            userVerification: 'updateUserVerification',
            deleteUser: 'deleteUser',
            updateUserSubscriptions: 'updateUserSubscriptions'
        }),
        convertDate(data) {
            const convert = m(data).format('DD/MM/YYYY HH:mm:ss');

            if (convert === 'Invalid date') {
                return '-';
            }
            return convert;
        },
        convertDateDay(data) {
            const convert = m(data).format('DD/MM/YYYY');

            if (convert === 'Invalid date') {
                return '-';
            }
            return convert;
        },
        rights() {
            const payload = {
                try_pass_before_login: this.getUser.try_pass_before_login,
                is_blocked: this.getUser.is_blocked,
                is_transaction_blocked: this.getUser.is_transaction_blocked,
                is_create_orders_blocked: this.getUser.is_create_orders_blocked,
                ban_ip: this.banIp,
                user_uuid: this.getUser.uuid,
                email: this.getUser.email
            }

            this.updateUserRights(payload).then(() => {
                this.banIp = 0;
            });
        },
        updateUserVerification(type, newValue) {
            const payload = {
                user_uuid: this.getUser.uuid,
                type: type,
                new_value: newValue
            }

            this.userVerification(payload);
        },
        deleteAccount() {
            this.deleteUser(this.getUser.uuid);
        },
        disabledSubscription(event, data) {
            const payload = {
                user_uuid: this.getUser.uuid,
                action: 'revoke',
                type: data.type
            }

            this.$confirm.require({
                target: event.currentTarget,
                message: 'Revoke immdiatelly subscriptions ?',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-sm',
                acceptClass: 'p-button-secondary p-button-outlined p-button-sm',
                rejectLabel: 'Cancel',
                acceptLabel: 'Revoke',
                accept: () => {
                    this.updateUserSubscriptions(payload)
                },
                reject: () => {
                    console.log('nok');
                }
            });
        },
        async giveSubscription() {
            const payload = {
                user_uuid: this.getUser.uuid,
                action: 'give',
                type: this.typeSubscriptionGive.type,
                expiration: m(this.expirationSubscriptionDate).format('YYYY-MM-DD HH:mm:ss')
            }

            await this.updateUserSubscriptions(payload).then(() => {
                this.typeSubscriptionGive = [];
                this.expirationSubscriptionDate = null;
            });
        }
    },
};
</script>

<style lang="scss">
    .m-center {
        margin: auto;
    }

    .inputs-row {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;

        .input-infos {
            margin: 5px;
        }
    }
</style>