<template>
    <div class="card lg:px-9 mt-3">
        <TabMenu v-model:activeIndex="active" :model="items" />

        <div v-if="active === 0" class="flex flex-wrap justify-center align-items-center">
            <div class="flex align-items-center border-2 border-dashed surface-border mt-2 mb-2 px-2 py-2">
                <h4 class="mr-3">2FA send on</h4>
                <Dropdown @change="change2FA()" v-model="security2FA" :options="cities" optionLabel="name" optionValue="code" class="w-full md:w-14rem" />
                <p class="ml-3">(for all users)</p>
            </div>
        </div>
        
        <div v-if="active === 1" class="flex flex-column">
            <div v-for="(action, n) in getEmergency" :key="n" :class="{ 'background-green': action.enabled, 'background-red': !action.enabled }" class="flex align-items-center border-2 border-dashed surface-border mt-2 mb-2 px-2 py-2">
                <ToggleButton @click="updateEmergency()" v-model="action.enabled" onIcon="pi pi-check" offIcon="pi pi-times" class="w-full sm:w-10rem mr-3" aria-label="Confirmation" />
                <h4>{{ action.code }}</h4>
                <p v-if="action.updated_at" class="ml-3">(last update {{ convertDate(action) }})</p>
            </div>
        </div>

        <Toast />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { filter } from 'lodash';
import m from 'moment';

export default {
    name: 'emergencyActions',
    data() {
        return {
            active: 0,
            items: [
                { label: 'Accounts', icon: 'pi pi-users' },
                { label: 'API', icon: 'pi pi-wave-pulse' }
            ],
            security2FA: 'email',
            cities: [
                { name: 'Email', code: 'email' },
                { name: 'Phone', code: 'phone' },
                { name: 'Disabled', code: 'disabled' },
            ]
        };
    },
    computed: {
        ...mapGetters({
            getEmergency: 'getEmergency'
        }),
    },
    methods: {
        ...mapActions({
            emergencyList: 'emergencyList',
            updateEmergencyList: 'updateEmergencyList',
            updateSupport2FA: 'updateSupport2FA'
        }),
        async updateEmergency() {
            const changeFilter = filter(this.getEmergency, (e) => {
                return e.enabled === true || e.enabled === false;
            });

            const payload = {
                emergency: changeFilter
            }

            await this.updateEmergencyList(payload).then(() => {
                this.$toast.add({ severity: 'success', summary: `${changeFilter[0].code}`, detail: 'Update OK', life: 3000 });
            }).catch(() => {
                this.$toast.add({ severity: 'error', summary: `${changeFilter[0].code}`, detail: 'Update error', life: 3000 });
            })
        },
        convertDate(data) {
            return m(data.updated_at).format('DD/MM/YYYY HH:mm:ss');
        },
        async change2FA() {
            const payload = {
                support: null
            }

            if (this.security2FA === 'email') {
                payload.support = 1;
            } else if (this.security2FA === 'phone') {
                payload.support = 2;
            } else if (this.security2FA === 'disabled') {
                payload.support = 0;
            }

            await this.updateSupport2FA(payload).then(() => {
                this.emergencyList();
                this.$toast.add({ severity: 'success', summary: `${this.security2FA}`, detail: 'Update OK', life: 3000 });
            }).catch(() => {
                this.emergencyList();
                this.$toast.add({ severity: 'error', summary: `${this.security2FA}`, detail: 'Update error', life: 3000 });
            })
        }
    },
    mounted() {
        this.emergencyList();
    }
};
</script>

<style scoped>
.background-red {
    background: rgba(255, 0, 0, 0.8);
}

.background-green {
    background: rgba(68, 255, 0, 0.35);
}
</style>