import { api } from '@/services/interceptors';

export default {
  state: {
    autoscraper: [],
    categories: []
  },
  getters: {
    getAutoscraper(state) {
        return state.autoscraper;
    },
    getScrapingCategories(state) {
      return state.categories;
    },
  },
  actions: {
    autoscraperStatistics({ commit }, payload) {
        api.get('api/scraping/statistics/' + payload).then((res) => {
            commit('STORAGE_AUTOSCRAPER', res.data.history);
        });
      },
      scrapingStatistics({ commit }) {
        api.get('api/scraping/statistics').then((res) => {
            commit('STORAGE_SCRAPING_STATISTICS', res.data.statistics);
        });
      },
      downloadScrapingXLSX({ commit }, payload) {
        commit('STORE_NOTHING');
        api.post('api/scraping/generate/xlsx', payload, { responseType: 'arraybuffer', responseEncoding: 'binary', headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          let url = window.URL.createObjectURL(blob);
          window.open(url);
        });
    },
  },
  mutations: {
    STORAGE_AUTOSCRAPER(currentState, autoscraper) {
        currentState.autoscraper = autoscraper;
    },
    STORAGE_SCRAPING_STATISTICS(currentState, statistics) {
      currentState.categories = statistics.categories;
  },
    STORE_NOTHING() { }
  }
};