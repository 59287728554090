<template>
    <div class="card lg:px-9">
        <TabMenu v-model:activeIndex="active" :model="items" />

        <div v-if="active === 0" class="mt-2">
            0
        </div>

        <div v-if="active === 1" class="mt-2 flex flex-column gap-2">
            <create />
        </div>

        <Toast />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import create from '@/components/dashboard/modules/storage/create.vue';
// import { FilterMatchMode } from 'primevue/api';
// import m from 'moment';

export default {
    components: { create },
    name: 'storagePage',
    data() {
        return {
            active: 1,
            items: [
                { label: 'Bottles', icon: 'pi pi-list' },
                { label: 'Add bottle', icon: 'pi pi-plus' },
                { label: 'Statistics', icon: 'pi pi-chart-pie' },
            ],
        };
    },
    computed: {
        ...mapGetters({
        }),
    },
    methods: {
        ...mapActions({
        }),
    },
    mounted() {
    }
};
</script>

<style>
</style>