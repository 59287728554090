import { api } from '@/services/interceptors';

export default {
  state: {
    transactions: [],
  },
  getters: {
    getTransactions(state) {
        return state.transactions;
    },
  },
  actions: {
    transactions({ commit }) {
        return new Promise((resolve, reject) => {
            api.get('api/statistics/transactions').then((res) => {
                commit('STORAGE_TRANSACTIONS', res.data.transactions);
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    getDownloadTransactionsXLSX({ commit }, payload) {
      commit('STORE_NOTHING');
      api.post('api/statistics/transactions/xlsx', payload, { responseType: 'arraybuffer', responseEncoding: 'binary', headers: { 'Content-Type': 'application/json' } })
      .then((res) => {
        let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        let url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    },
    scrapingStatisticsXLSX({ commit }, payload) {
      commit('STORE_NOTHING');
      return new Promise((resolve, reject) => {
          api.post('api/statistics/xlsx/scraping', payload, { responseType: 'arraybuffer', responseEncoding: 'binary', headers: { 'Content-Type': 'application/json' } })
          .then((res) => {
            let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            let url = window.URL.createObjectURL(blob);
            window.open(url);
            resolve();
          }).catch(() => {
              reject();
          })
      })
  },
  },
  mutations: {
    STORAGE_TRANSACTIONS(currentState, transactions) {
        currentState.transactions = transactions;
    },
    STORE_NOTHING() { }
  }
};