import { api } from '@/services/interceptors';

export default {
  state: {
    verified: [],
    unverified: [],
  },
  getters: {
    getVerifiedOrders(state) {
        return state.verified;
    },
    getUnverifiedOrders(state) {
        return state.unverified;
    },
  },
  actions: {
    verifiedOrders({ commit }) {
        api.get('api/orders/verified').then((res) => {
          commit('STORAGE_VERIFIED_ORDERS', res.data.verified);
        });
    },
    unverifiedOrders({ commit }) {
        api.get('api/orders/unverified').then((res) => {
          commit('STORAGE_UNVERIFIED_ORDERS', res.data.unverified);
        });
    },
    checkOrder({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      api.put('api/orders/order/check', payload).then(() => {
        dispatch('verifiedOrders');
        dispatch('unverifiedOrders');
      });
    },
    deleteOrder({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      api.delete('api/orders/order/' + payload).then(() => {
        dispatch('verifiedOrders');
        dispatch('unverifiedOrders');
      });
    },
  },
  mutations: {
    STORAGE_VERIFIED_ORDERS(currentState, verified) {
        currentState.verified = verified;
    },
    STORAGE_UNVERIFIED_ORDERS(currentState, unverified) {
        currentState.unverified = unverified;
    },
    STORE_NOTHING() { }
  }
};