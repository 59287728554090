import { api } from '@/services/interceptors';

export default {
  state: {
    events: [],
  },
  getters: {
    getEvents(state) {
        return state.events;
    },
  },
  actions: {
    events({ commit }) {
      api.get('api/events').then((res) => {
        commit('STORAGE_EVENTS', res.data.events);
      });
    },
    addEvent({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      api.post('api/events', payload).then(() => {
        dispatch('events');
      });
    },
    updateEvent({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      api.put('api/events', payload).then(() => {
        dispatch('events');
      });
    },
    deleteEvent({ commit, dispatch }, payload) {
      commit('STORE_NOTHING');
      api.delete('api/events/' + payload, payload).then(() => {
        dispatch('events');
      });
    },
  },
  mutations: {
    STORAGE_EVENTS(currentState, events) {
        currentState.events = events;
    },
    STORE_NOTHING() { }
  }
};