import { api } from '@/services/interceptors';

export default {
  state: {
    bottles: [],
    currentBottle: null
  },
  getters: {
    getBottles(state) {
        return state.bottles;
    },
    getBottle(state) {
        return state.currentBottle;
    },
  },
  actions: {
    bottles({ commit }) {
        return new Promise((resolve, reject) => {
            api.get('api/storage/bottles').then((res) => {
                commit('STORAGE_BOTTLES', res.data.bottles);
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    bottle({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.get('api/storage/bottle/' + payload).then((res) => {
                commit('STORAGE_BOTTLE', res.data.bottle);
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
    addBottleToStorage({ commit }, payload) {
        return new Promise((resolve, reject) => {
            api.post('api/storage/bottle', payload).then(() => {
                commit('STORE_NOTHING');
                resolve();
            }).catch(() => {
                reject();
            })
        })
    },
  },
  mutations: {
    STORAGE_BOTTLES(currentState, bottles) {
        currentState.bottles = bottles;
    },
    STORAGE_BOTTLE(currentState, currentBottle) {
        currentState.currentBottle = currentBottle;
    },
    STORE_NOTHING() { }
  }
};