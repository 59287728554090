import { api } from '@/services/interceptors';

export default {
  state: {
    browser: [],
    products: [],
    banip: [],
    backoffice: []
  },
  getters: {
    getBrowser(state) {
        return state.browser;
    },
    getProducts(state) {
        return state.products;
    },
    getBanIP(state) {
      return state.banip;
    },
    getBackoffice(state) {
      return state.backoffice;
    },
  },
  actions: {
    historyBrowser({ commit }) {
        api.get('api/history/browser').then((res) => {
            commit('STORAGE_BROWSER', res.data.history);
        });
    },
    historyProducts({ commit }) {
        api.get('api/history/product').then((res) => {
            commit('STORAGE_PRODUCTS', res.data.history);
        });
    },
    historyBanIP({ commit }) {
      api.get('api/history/banlist').then((res) => {
          commit('STORAGE_BANIP', res.data.history);
      });
    },
    actionsByIP({ commit }) {
      commit('STORE_NOTHING');
      api.get('api/history/actions/ip').then((res) => {
          // commit('STORAGE_BANIP', res.data.history);
          console.log(res.data);
      });
    },
    historyBackoffice({ commit }) {
      commit('STORE_NOTHING');
      api.get('api/history/backoffice').then((res) => {
        commit('STORAGE_BACKOFFICE', res.data.history);
      });
    },
    banIP({ commit }, payload) {
      commit('STORE_NOTHING');
      api.post('api/history/actions/ip', payload).then(() => {});
    },
  },
  mutations: {
    STORAGE_BROWSER(currentState, browser) {
        currentState.browser = browser;
    },
    STORAGE_PRODUCTS(currentState, products) {
        currentState.products = products;
    },
    STORAGE_BANIP(currentState, banip) {
      currentState.banip = banip;
    },
    STORAGE_BACKOFFICE(currentState, backoffice) {
      currentState.backoffice = backoffice;
    },
    STORE_NOTHING() { }
  }
};