<template>
    <form v-if="!getIsAuth" class="m-center surface-card p-4 shadow-2 border-round w-full lg:w-6">
        <div>
            <label for="email" class="block text-900 font-medium mb-2">Email</label>
            <InputText id="email" type="email" placeholder="Email address" class="w-full mb-3" v-model="payload.email" required />

            <label for="password" class="block text-900 font-medium mb-2">Password</label>
            <InputText id="password" type="password" placeholder="Password" class="w-full mb-3" v-model="payload.password" required />

            <Button @click="sendLogin()" :disabled="payload.email.length < 15 || payload.password.length < 10" label="Continue" icon="pi pi-user" class="w-full"></Button>
        </div>
    </form>
</template>

<script>  
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'loginPage',
    data() {
        return {
            payload: {
                email: '',
                password: ''
            },
            rememberMe: false
        };
    },
    computed: {
        ...mapGetters({
            getIsAuth: 'getIsAuth'
        })
    },
    methods: {
        ...mapActions({
            login: 'login'
        }),
        sendLogin() {
            if (this.payload.email && this.payload.password) {
                this.login(this.payload);
            }
        }
    }
};
</script>

<style>
    .m-center {
        margin: auto;
    }
</style>