<template>
    <div class="card lg:px-9">
        <div style="height: 20%" class="border-2 border-dashed surface-border mb-2 p-2">
            <div>
                <span class="flex flex-column gap-2">
                    <label for="ticker">Identifier* -> not normalized bottles</label>
                    <InputText id="ticker" placeholder="foursquare or foursquare,ardbeg,chartreuse" v-model="keywords" />
                    <small id="ticker-help">*title must be contain... (ex: FOURSQUARE or FOURSQUARE,ARDBEG,CHARTREUSE)</small>
                </span>
            </div>
        </div>
        <div style="height: 20%" class="border-2 border-dashed surface-border mb-2 p-2">
            <div>
                <Button @click="download()" icon="pi pi-download" label="Download" severity="success" class="ml-2 flex-row" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'scrapingGenerateXLSX',
    data() {
        return {
            keywords: null
        };
    },
    methods: {
        ...mapActions({
            downloadScrapingXLSX: 'downloadScrapingXLSX'
        }),
        download() {
            const payload = {
                keywords: this.keywords
            }
            
            this.downloadScrapingXLSX(payload).then(() => {
                this.keywords = null;
            });
        }
    }
};
</script>